@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-loading-screen {
  background-image: url('../src/Assets/Images/csnswbg-1.jpg');
}

/* Styles for not found screen */
@keyframes twinkleAnimation {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
}

.star-twinkle {
  animation: twinkleAnimation 2s infinite;
}

.bg-not-found-screen {
  background-image: url('../src/Assets/Images/notFound.svg');
}

.bg-student-not-found {
  background-image: url('../src/Assets/Images/student-not-found.svg');
}

.bg-internal-server-error {
  background-image: url('../src/Assets/Images/internalServer.svg');
}

.bg-unauthorized {
  background-image: url('../src/Assets/Images/unauthorized.svg');
}

.bg-content-not-found {
  background-image: url('../src/Assets/Images/content-not-found.svg');
}


/* Styles for Ant Switch */
.custom-switch .ant-switch{
  @apply bg-gray-500 !important;
}

.custom-switch .ant-switch-checked {
 @apply bg-[#4CAF50] hover:bg-[#4CAF50] !important; /* Green */
}

/* Styles for Ant Dropdown */
.custom-dropdown-button{
  @apply bg-[#DDDDDD] !important;
}

.custom-dropdown-button:hover{
  @apply text-black bg-[#d4cfcf] border-[#d4cfcf] !important;
}